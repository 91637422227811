<template>
  <div class="container-personalcenter" id="disableRightClicking">
    <div class="publicpersonalcenter-information">
      <div class="publicpersonalcenter-information-left">
        <div class="publicpersonalcenter-information-top">
          <img :src="[
              image === '' ? require('../../assets/images/25.png') : image
            ]" alt="" />
          <p>{{ name }}</p>
          <span>UID：{{ id }}</span>
          <span v-if="mobile">{{ mobile | phoneFilter }}</span>
        </div>
        <div class="publicpersonalcenter-information-middle">
          <ul>
            <li :class="[informationShow === 1 ? 'active' : '']">
              <a href="javascript:;" @click="toggleInformation(1)">店铺申请</a>
            </li>
            <li :class="[informationShow === 4 ? 'active' : '']">
              <a href="javascript:;" @click="toggleInformation(4)">直播申请</a>
            </li>
            <!--   <li :class="[informationShow === 2 ? 'active' : '']">
              <a href="javascript:" @click="toggleInformation(2)">个人资料</a>
            </li> -->
            <li><a href="javascript:" @click="goOut()">退出登录</a></li>
          </ul>
        </div>

      </div>
      <div class="publicpersonalcenter-information-right">
        <!--        <div class="publicpersonalcenter-individuals" v-if="informationShow === 2">
          <div class="publicpersonalcenter-individuals-text">个人基本信息</div>
          <div class="publicpersonalcenter-individuals-avart">
            <span>头像</span>
            <div class="replacement">
              <img :src="[
                  image === '' ? require('../../assets/images/25.png') : image
                ]" alt="" />
              <input type="file" id="file" accept="image/*" @change="handleFile" class="hiddenInput" />
              <span>
                更换头像
              </span>
            </div>
          </div>
          <div class="nicknames">
            <span>昵称</span>
            <span>{{ name }}</span>
            <p @click="editNicknames()">
              <a href="javascript:">编辑</a>
              <i class="iconfont icon-bianji"></i>
            </p>
          </div>
          <div class="nicknames">
            <span>手机号</span>
            <span>{{ mobile }}</span>
          </div>
        </div> -->
        <shopApply v-if="informationShow === 1"></shopApply>
        <liveApply v-if="informationShow === 4"></liveApply>
      </div>
    </div>
  </div>
</template>
<script>
  import shopApply from './components/shopApply'
  import liveApply from './components/liveApply'
  export default {
    name: 'Personalcenter',
    components: {
      shopApply,
      liveApply
    },
    data() {
      return {
        name: localStorage.getItem('user_name'),
        mobile: localStorage.getItem('user_phone'),
        id: localStorage.getItem('enterprise_id'),
        informationShow: 1,
        image: ''
      }
    },
    filters: {
      // 手机号分割
      phoneFilter(value) {
        const start = value.slice(0, 3)
        const main = value.slice(3, 7)
        const end = value.slice(7)
        return start + ' ' + main + ' ' + end
      }
    },
    created() {},
    methods: {
      // 切换
      toggleInformation(index) {
        this.informationShow = index
        localStorage.setItem('informationShow', index)
      },
      // 退出登录
      goOut() {
        localStorage.clear()
        this.$message.success('退出成功')
        this.$router.push({
          path: '/homemain',
          name: 'Homemain'
        })
        // this.reload()
        var time = setTimeout(() => {
          location.reload()
          clearInterval(time)
        }, 300)
      }
    }
  }
</script>

<style lang="less" scoped>
  .container-personalcenter {
    background: rgb(248, 248, 248);

    .publicpersonalcenter-information {
      display: flex;
      margin-left: 90px;

      .publicpersonalcenter-information-left {
        .publicpersonalcenter-information-top {
          width: 290px;
          height: 305px;
          background: #fff;
          margin-top: 30px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;

          img {
            width: 130px;
            height: 130px;
            border-radius: 50%;
          }

          p {
            font-size: 24px;
            font-weight: 500;
            color: #333;
            margin-bottom: 0;
          }

          span {
            font-size: 16px;
            font-weight: 400;
            color: #666;
          }
        }

        .publicpersonalcenter-information-middle {
          width: 290px;
          height: 322px;
          background: #fff;
          box-sizing: border-box;
          padding: 20px 0;
          margin-bottom: 20px;

          ul {
            height: 100%;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            font-weight: 400;

            li {
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
              position: relative;

              &:hover {

                // border-left: 4px solid #cda156;
                font-weight: 600;

                &::before {
                  display: block;
                  content: '';
                  width: 4px;
                  height: 24px;
                  background: #cda156;
                  position: absolute;
                  left: 0;
                }
              }

              a {
                color: #333;
                position: relative;
              }

              .circle {
                width: 7px;
                height: 7px;
                background: #E14632;
                border-radius: 50%;
                right: -10px;
                top: 4px;
                position: absolute;
              }
            }

            .active {
              // border-left: 4px solid #cda156;
              font-weight: 600;

              &::before {
                display: block;
                content: '';
                width: 4px;
                height: 24px;
                background: #cda156;
                position: absolute;
                left: 0;
              }
            }
          }
        }

        .publicpersonalcenter-information-bottom {
          width: 290px;
          height: 218px;
          background: #fff;
          box-sizing: border-box;
          padding: 20px 0;
          margin-bottom: 20px;

          ul {
            height: 100%;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            font-weight: 400;

            li {
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
              position: relative;

              a {
                color: #333;
              }
            }

            li:hover {
              //border-left: 4px solid #cda156;
              font-weight: 600;

              &::before {
                display: block;
                content: '';
                width: 4px;
                height: 24px;
                background: #cda156;
                position: absolute;
                left: 0;
              }
            }

            .active {
              //border-left: 4px solid #cda156;
              font-weight: 600;

              &::before {
                display: block;
                content: '';
                width: 4px;
                height: 24px;
                background: #cda156;
                position: absolute;
                left: 0;
              }
            }
          }
        }
      }

      .publicpersonalcenter-information-right {
        margin-top: 30px;
        width: 1400px;
        // height: 622px;
        margin-bottom: 60px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 30px;

        .publicpersonalcenter-individuals {
          // margin-left: 50px;
          background: #fff;
          padding: 40px 70px 50px 70px;
        }

        .publicpersonalcenter-individuals-text {
          font-size: 36px;
          font-weight: 600;
          color: #333;
        }

        .publicpersonalcenter-content {
          display: flex;
          margin-top: 30px;

          span {
            font-size: 20px;
            color: #333;
            font-weight: 600;
          }

          textarea {
            resize: none;
            outline: none;
            width: 1060px;
            height: 400px;
            margin-left: 20px;
            padding: 10px 0 0 10px;
          }
        }

        button {
          font-weight: 600;
          font-size: 20px;
          width: 260px;
          color: #ffffff;
          height: 50px;
          background: #cda156;
          border-radius: 8px;
          border: none;
          margin-left: 100px;
          margin-top: 40px;
        }

        .publicpersonalcenter-individuals-avart {
          margin-top: 60px;
          padding-bottom: 35px;
          display: flex;

          span {
            font-size: 20px;
            font-weight: 400;
            color: #333;
          }

          .replacement {
            margin-left: 70px;
            position: relative;
            width: 150px;
            height: 113px;
            overflow: hidden;

            span {
              position: absolute;
              font-size: 16px;
              font-family: sans-serif;
              left: 0;
              bottom: 0;
              z-index: 1;
              color: #fff;
              display: block;
              width: 100%;
              height: 63px;
              text-align: center;
              line-height: 60px;
              background: rgba(0, 0, 0, 0.2);
            }

            img {
              width: 100%;
              height: 100%;
            }

            // 隐藏’未选择任何文件‘
            input[type='file'] {
              color: transparent;
            }

            input {
              width: 100%;
              position: absolute;
              height: 63px;
              font-size: 12px;
              font-family: sans-serif;
              left: 0;
              bottom: 0;
              opacity: 0;
              z-index: 100;
            }
          }
        }

        .nicknames {
          display: flex;
          padding: 34px 0;
          align-items: center;
          border-top: 1px solid #dedede;
          text-align: left;

          span:nth-child(1) {
            font-size: 20px;
            font-weight: 600;
            color: #333;
            width: 70px;
          }

          span:nth-child(2) {
            font-size: 16px;
            font-weight: 400;
            color: #333;
            padding-left: 70px;
          }

          p {
            margin-left: auto;

            a {
              color: #6e5121;
              font-size: 16px;
              margin-left: auto;
              padding-right: 10px;
            }

            i {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
</style>
