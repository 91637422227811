<template>
  <div class="shopApply" v-if="isRequest>1">
    <!-- 未开通店铺 -->
    <div class="open-shop-msg" v-if="!isOpenShop">
      <img class="shop-img" src="../../../assets/images/openshopmsg.png" />
      <div>请您开通店铺后，再申请直播</div>
    </div>
    <!-- 已开通店铺 -->
    <template v-else>
      <!-- 有直播申请 -->
      <template v-if="liveData">
        <!-- <div class="open-shop-msg" v-if="liveData.apply_status==3">
          <img class="shop-img" src="../../../assets/images/kaitong.png" />
          <div>您的直播间已经开通，快去装修吧～</div>
        </div>
        <template v-else>
          <div class="title">直播申请</div>
          <div class="list">
            申请时间：{{liveData.apply_time}}
          </div>
          <div class="list">申请类型：直播申请</div>
          <div class="list">申请状态：<span
              :class="liveData.apply_status|statusColor">{{liveData.apply_status|statusText}}</span>
          </div>
          <div class="list">
            审核原因：{{liveData.apply_status==2?liveData.check_note:'无'}}
          </div>
          <div class="bottom">
            <span class="btn" v-if="liveData.apply_status==2" @click="edit()">编辑再次提交申请</span>
            <span class="btn" v-if="liveData.apply_status==4" @click="goPay()">去缴费</span>
          </div>
        </template> -->
        <div class="title">直播申请</div>
        <div class="list">
          申请时间：{{liveData.apply_time}}
        </div>
        <div class="list">申请类型：直播申请</div>
        <div class="list">申请状态：<span
            :class="liveData.apply_status|statusColor">{{liveData.apply_status|statusText}}</span>
        </div>
        <div class="list">
          审核原因：{{liveData.apply_status==2?liveData.check_note:'无'}}
        </div>
        <div class="bottom">
          <span class="btn" v-if="liveData.apply_status==2" @click="edit()">编辑再次提交申请</span>
          <span class="btn" v-if="liveData.apply_status==4" @click="goPay()">去缴费</span>
        </div>
      </template>
      <!-- 无直播申请 -->
      <template v-else>
        <div class="pay-success" v-if="isOpenShop">
          <img class="pay-img" src="../../../assets/images/huaban.png" />
          <div>您已成功缴费，可以申请直播哦～</div>
          <span @click="openLive()">去开通直播</span>
        </div>
        <div class="no-msg" v-else>
          <img src="../../../assets/images/nolive.png" />
          <div>您暂时还没有任何直播申请～</div>
          <span @click="openLive()">去开通直播</span>
        </div>
      </template>
    </template>
    <el-dialog title="开通直播间" :visible.sync="dialogVisible" width="30%">
      <div class="open-shop">
        <div class="flex dialog-1">
          <span>服务费(一年)</span>
          <span>1,000.00 元</span>
        </div>
        <div class="flex dialog-1 border-bottom">
          <span>保证金(一年)</span>
          <span>5,000.00 元</span>
        </div>
        <div class="flex dialog-2 border-bottom">
          <span>待支付金额</span>
          <span>6,000.00 元</span>
        </div>
        <!-- <div class="flex" @click="paySelect()">
          <div class="dialog-1-1">
            <i class="iconfont icon-qianbao1" style="color:rgb(205, 152, 86);"></i>
            <span class="pay-title">余额支付</span>
            <span class="font16">当前账户余额为</span>
            <span class="font16 num">33</span>
            <span class="font16">元</span>
            <span class="recharge">充值</span>
          </div>
          <img class="choose" src="../../../assets/images/juxing1.png" />
        </div> -->
        <div class="flex" @click="paySelect(1)">
          <div class="dialog-1-1">
            <i class="iconfont icon-weixinzhifu" style="color:rgb(0, 179, 45);"></i>
            <span class="pay-title">微信支付</span>
          </div>
          <img class="choose" src="../../../assets/images/juxing2.png" v-if="payType==1" />
          <img class="choose" src="../../../assets/images/juxing1.png" v-else />
        </div>
        <div class="flex" @click="paySelect(2)">
          <div class="dialog-1-1">
            <i class="iconfont icon-zhifubao" style="color:rgb(0, 162, 234);"></i>
            <span class="pay-title">支付宝支付</span>
          </div>
          <img class="choose" src="../../../assets/images/juxing2.png" v-if="payType==2" />
          <img class="choose" src="../../../assets/images/juxing1.png" v-else />
        </div>
        <div class="pay-code">
          <ewmCode ref="ewmCode" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <span class="success-btn" @click="yesPay()">确定缴费</span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getMallApplyRecord,
    mallPay,
    getMallOrderStatus
  } from '../../../api/index'
  import ewmCode from '../../../components/ewmCode.vue'
  export default {
    components: {
      ewmCode
    },
    data() {
      return {
        dialogVisible: false,
        payType: 1, //1:微信；2:支付宝
        liveData: null,
        isOpenShop: false,
        outTradeNo: '',
        isRequest: 0 //是否全部请求
      }
    },
    created() {
      this.getShopStatus()
    },
    methods: {
      async mallPay() {
        const res = await mallPay({
          mallId: Number(localStorage.getItem('enterprise_id')),
          payScene: 2, //1:店铺缴费 2:直播缴费
          bond: 1, //	保证金 单位 分
          serviceFee: 1, //	服务费 单位 分
          payPrice: 2, //支付金额 单位分
          payType: this.payType, //1:微信；2:支付宝
          payClientType: 1 //1:PC端:2:APP
        })
        if (res.code === 0) {
          this.outTradeNo = res.data.TradeNo
          this.$nextTick(() => {
            this.$refs.ewmCode.open(res.data.url)
          })
        } else {
          this.$message.error(res.msg)
        }
      },
      async getShopStatus() { //查询是否开通店铺
        const res = await getMallApplyRecord({
          authorization: localStorage.getItem('accessToken'),
          enterprise_id: Number(localStorage.getItem('enterprise_id')),
          apply_type: 1
        })
        if (res.flag === true) {
          this.isRequest++
          if (!res.data) return
          const data = res.data[0]
          this.isOpenShop = data.apply_status == 3 && data.pay_status == 2 //审核通过-已缴费
          this.getMallApplyRecord()
        } else {
          this.$message.error(res.msg)
        }
      },
      async getMallApplyRecord() {
        const res = await getMallApplyRecord({
          authorization: localStorage.getItem('accessToken'),
          enterprise_id: Number(localStorage.getItem('enterprise_id')),
          apply_type: 2
        })
        if (res.flag === true) {
          this.isRequest++
          if (!res.data) return
          if (res.data[0].check_note) {
            res.data[0].check_note = res.data[0].check_note.split('|')
            res.data[0].check_note = res.data[0].check_note[0]
          }
          this.liveData = res.data[0]
          if (this.liveData.apply_status == 3 && this.liveData.pay_status == 1) { //审核通过-待缴费
            this.liveData.apply_status = 4
          }
        } else {
          this.$message.error(res.msg)
        }
      },
      paySelect(type) {
        this.payType = type
        this.mallPay()
      },
      edit() {
        this.$router.push({
          path: '/liveFor',
          name: 'liveFor',
          query: {
            edit: 1
          }
        })
      },
      goPay() {
        this.dialogVisible = true
        this.mallPay()
      },
      yesPay() {
        this.dialogVisible = false
        this.getMallOrderStatus()
      },
      openLive() {
        this.$router.push({
          path: '/liveFor',
          name: 'liveFor'
        })
      },
      async getMallOrderStatus() {
        const res = await getMallOrderStatus({
          outTradeNo: this.outTradeNo
        })
        if (res.data.resultCode === 0) {
          this.$message.success('支付成功')
          this.getMallApplyRecord()
        } else {
          this.$message.error(res.data.resultMsg || res.msg)
        }
      }
    },
    filters: {
      statusText(status) { //拍卖会状态
        const msg = {
          1: '审核中',
          2: '审核不通过',
          3: '审核通过-已缴费',
          4: '审核通过-待缴费'
        }
        return msg[status]
      },
      statusColor(status) {
        return status == 1 ? 'orange' : status == 2 ? 'red' : 'green'
      }
    }
  }
</script>

<style lang="less" scoped>
  .shopApply {
    /deep/ .el-dialog__footer {
      text-align: center;
    }

    /deep/ .el-dialog__header {
      text-align: center;
    }

    /deep/ .el-dialog__title {
      font-size: 24px;
      font-weight: 600;
      color: #333;
    }

    background: #fff;
    width: 100%;
    height: 100%;
    padding: 40px 120px;

    .open-shop-msg,
    .pay-success,
    .no-msg {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      >img {
        width: 220px;
        height: 150px;
      }

      >div {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        margin-top: 20px;
      }

      >span {
        width: 200px;
        height: 44px;
        background: rgba(255, 255, 255, .7);
        border-radius: 8px;
        border: 1px solid #CDA156;
        color: #CDA156;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        font-size: 18px;
        cursor: pointer;
      }
    }

    .pay-img {
      width: 265px !important;
      height: 180px !important;
    }

    .shop-img {
      width: 250px !important;
      height: 200px !important;
    }

    .title {
      font-size: 36px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 10px;
    }

    .list {
      height: 88px;
      border-bottom: 1px solid #f3f3f3;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 400;
      color: #333333;

      >span {
        font-size: 20px;
        font-weight: 600;
      }

      .orange {
        color: #CDA156;
      }

      .red {
        color: #CD5656;
      }

      .green {
        color: #60C257;
      }
    }

    .bottom {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .btn {
        width: 280px;
        height: 44px;
        background: rgba(205, 161, 86, .7);
        border-radius: 8px;
        font-size: 18px;
        font-weight: 400;
        color: #6E5121;
        text-align: center;
        line-height: 44px;
        cursor: pointer;
      }
    }



    .success-btn {
      display: inline-block;
      width: 200px;
      height: 44px;
      background: rgba(205, 161, 86, .7);
      border-radius: 8px;
      font-weight: 600;
      color: #6E5121;
      text-align: center;
      line-height: 44px;
      cursor: pointer;
    }

    .open-shop {
      >.flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      >.dialog-1 {
        padding-bottom: 20px;

        >span {
          font-size: 20px;
          font-weight: 400;
          color: #333333;
        }
      }

      >.dialog-2 {
        padding: 20px 0;
        margin-bottom: 10px;

        >span {
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          font-weight: bold;
        }
      }

      >.border-bottom {
        border-bottom: 1px solid #DEDEDE;
      }

      >.pay-code {
        display: flex;
        justify-content: center;
      }

      .dialog-1-1 {
        display: flex;
        align-items: center;
        padding: 10px 0;

        .icon-qianbao1 {
          font-size: 22px;
        }

        .icon-weixinzhifu {
          font-size: 22px;
        }

        .icon-zhifubao {
          font-size: 22px;
        }

        .font16 {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
        }

        .num {
          margin: 0 4px;
          color: #CDA156;
        }

        .recharge {
          margin-left: 6px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #CDA156;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .pay-title {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        padding: 0 10px;
      }

      .choose {
        width: 20px;
        height: 20px;
      }
    }
  }
</style>
